import './App.css';
import {useState, useEffect} from 'react'

// Components
import {Deploy} from './Component/Deploy/deploy'
import HelpModal from './Component/Deploy/HelpModal';
import IconButton from './Component/Deploy/IconButton';

import QuestionButton from './Component/Deploy/QuestionButton';
import { fetchData } from './Component/Deploy/fetchData';

// Images
import bookImage from './Images/book.jpeg'; 
import SpeakIcon from './Images/speak.png';
import CopyIcon from './Images/copy.png';
import RedoIcon from './Images/redo.png';
import StopIcon from './Images/stop.png';
import QuestionIcon from './Images/question.png';

function App() {
  const [userInput, setUserInput] = useState('');
  const [message, setMessage] = useState('');
  const [isLoad, setIsLoad] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const [topYoutube, setTopYoutube] = useState({ title: '', link: '' });
  const [topBlog, setTopBlog] = useState({ title: '', link: '' });

  let speechSynthesis = window.speechSynthesis;
  let speech;


  // Function to handle user input change
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoad(true);
    setMessage('');
    console.log("Sending request with userInput:", userInput);

    try {
      const response = await fetch('/llama', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userInput: userInput })
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response received:", data);
        console.log(data.message);
        console.log(data.top_youtube);
        console.log(data.top_blog);
        setMessage(data.message);
        setTopYoutube(data.top_youtube);
        setTopBlog(data.top_blog);
      } else {
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    } finally {
      setIsLoad(false);
    }
  };

  const handleStop = () => {
    if (isSpeaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    }
  };

  const handleSpeak = () => {
    speech = new SpeechSynthesisUtterance(message);
    // Find and set a specific voice
    var voices = window.speechSynthesis.getVoices();
    speech.voice = voices.filter(voice => voice.name == "Google UK English Female")[0]; // Example: Change to Google UK English Male voice
    setIsSpeaking(true);
    speechSynthesis.speak(speech);
    speech.onend = () => {
      setIsSpeaking(false);
    };
  };

  const toggleHelpModal = () => {
    setIsHelpModalOpen(!isHelpModalOpen);
  };

  const handleIconClick = async (action) => {
    console.log('Icon clicked:', action);
  
    if (action === 'Speak') {
      handleSpeak();
    }
    if (action === 'Copy') {
      navigator.clipboard.writeText(message);
    }
    if (action === 'Stop') {
      handleStop();
    }
    if (action === 'Help') {
      toggleHelpModal();
    }
  };

  return (
    <div className="App">
      <h1>Welcome to Start. Scale. Exit. Repeat. AI!</h1>
      <h2>You can ask me anything about the book. You can say things like ...</h2>
      <div>
        <QuestionButton setUserInput={setUserInput}>What makes a startup successful?</QuestionButton>
        <QuestionButton setUserInput={setUserInput}>What is the 4 sticky note business plan?</QuestionButton>
        <QuestionButton setUserInput={setUserInput}>What can I do to get the highest possible valuation for my company on exit?</QuestionButton>
        {/* <QuestionButton setUserInput={setUserInput}>Question 5</QuestionButton>
        <QuestionButton setUserInput={setUserInput}>Question 6</QuestionButton>
        <QuestionButton setUserInput={setUserInput}>Question 7</QuestionButton> */}
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
          value={userInput}
          onChange={handleInputChange}
          placeholder="Enter your question or comment..."
        />
        <button type="submit">Submit</button>
      </form>
      {!message && isLoad ? (
        <div className="loading-indicator">
          <p>Loading...</p>
        </div>
      ) : (
        message && (
          <div className="response">
            <p className="response-text">
              <Deploy prop={message} />
            </p>
            <h3>Read more:</h3>
            <ul>
              {topBlog.map((item, index) => {
                let fontSize;
                switch(index) {
                  case 0:
                    fontSize = '20px';
                    break;
                  case 1:
                    fontSize = '18px';
                    break;
                  case 2:
                    fontSize = '15px';
                    break;
                    default:
                    fontSize = '15px'; // Default font size for any additional links
                  }
                  
                  return (
                  <li key={index}>
                    <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: fontSize }}>
                      {item.title}
                      </a>
                      </li>
                      );
                      })}
                      </ul>
            <h3>Watch related videos:</h3>
            <div classname = "video-table">
              {topYoutube.map((item, index) => (
              <div className="video-cell" key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img src={item.thumbnail} alt="YouTube Thumbnail" className="video-thumbnail" />
                  </a>
                  <div className="video-title">{item.title}</div>
                  </div>
                  ))}
          </div>
          </div>
        )
      )}
      <HelpModal isOpen={isHelpModalOpen} closeModal={() => setIsHelpModalOpen(false)} />
      <div className="icon-buttons" style={{ display: 'flex' }}>
        <IconButton icon={SpeakIcon} onClick={() => handleIconClick('Speak')} label="Read out-loud" />
        {isSpeaking && <IconButton icon={StopIcon} onClick={() => handleIconClick('Stop')} label="Stop reading" />}
        <IconButton icon={CopyIcon} onClick={() => handleIconClick('Copy')} label="Copy text" />
        <IconButton icon={QuestionIcon} onClick={() => handleIconClick('Help')} label="Help" />
      </div>
      <br></br>
      <small>*This application is for entertainment purposes only and uses OpenAI's API. 
As this is an artificially intelligent entity any claims or statements made may be untrue. 
We do not make any claims to be a human. 
This application is in compliance with OpenAI's terms of service and usage policies.
All technology should be used ethically. 
As such, this application should not be used for any purposes that are harmful or unethical. 
Violation of these terms may result in being permanently banned and/or reported to OpenAI.
This application is unable to provide medical, legal, or financial advice. Users should consult human professionals for such matters.
For support or queries about this disclaimer please reach out to ops@startup.club.
</small>
    </div>
  );
        }
// handleSpeak, fetchData, setTopYoutube, set
export default App;